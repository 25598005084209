<template>
  <div class="login">
    <!-- <h1>Login <span v-if="isDev">[DEVELOPMENT]</span></h1> -->
    <div class="pane">
      <img
        src="@/assets/images/ap-logo.svg"
        style="max-width: 400px"
        alt="AccidentPlan Logo"
      />
      <h1>404 Not Found</h1>
      <p>
        How did you end up here?
      </p>
      <el-link style="font-size: 1.2rem" type="primary" href="#/"
        >Return to app</el-link
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
.login {
  height: 100%;
  background-color: #636363;
  padding: 1rem;

  .pane {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    // border: 1px solid lightgray;
    border-radius: 5px;
    transition: 0.3s;
    width: 400px;
    margin: auto;
    padding: 20px 16px;
    background: white;
    text-align: center;

    button {
      margin-bottom: 20px;
    }
  }
  .error {
    color: red;
  }

  h1 {
    text-align: center;
  }
  .login-input {
    margin-bottom: 10px;
  }
  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    // width: 200px;
  }
}
</style>
